import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from "gatsby";
import Layout from "../components/layout";

import LivingSoilBlog from "../images/living-soil-blog.png";
import BiochemSequence from "../images/biochem-sequence.png";
import IndigoGardens from "../images/indigo-gardens.png";
import SimonLawnCare from "../images/slc.png";
import ArrowIcon from "../images/arrow-icon.png";

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '30px',
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  ctaButtons: {
    backgroundColor: '#ef6830',
    color: '#202020',
  },
  ctaLinks: {
    textDecoration: 'none',
  },
  ctaLinksSecondary: {
    textDecoration: 'none',
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [
    {image: LivingSoilBlog, heading: 'Agzaar', description:'Ranked site #1 for multiple keywords in 4 months with great SEO practices and link building, resulting in long-term online product sales.', title: 'Agzaar'}, 
    {image: BiochemSequence, heading: 'Soil Nerd', description:'Grew a 12k engaged instagram following by simplifying complex topics, created and launched a viral video.', title: 'Soil Nerd'}, 
    {image: IndigoGardens, heading: 'Indigo Gardens', description:'Positioned a fledgling farm as a market leader in organic growing practices that allowed them to take on big partnerships.', title: 'Indigo Gardens'}, 
    {image: SimonLawnCare, heading: 'Simon Lawn Care', description:'Skyrocketed sales from $0 to over $1M in three years through an online and physical marketing strategy.', title: 'Simon Lawn Care'}, 
];

export default function MarketingPage() {
  const classes = useStyles();

  return (
    <Layout>
    <React.Fragment>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="m">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Effective Marketing
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              for organizations that deserve to be discovered
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Link to="/contact" className={classes.ctaLinks}>
                  <Button variant="contained" className={classes.ctaButtons}>
                    Contact Carl
                  </Button>
                  </Link>
                </Grid>
                <Grid item>
                <Link to="/growth-hacking-101" className={classes.ctaLinksSecondary}>
                  <Button variant="outlined">
                    Learn more about marketing
                  </Button>
                </Link>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <img src={ArrowIcon} className={classes.icon} alt=""></img>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          {/* <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Previous projects
          </Typography> */}
          <Grid container spacing={4}>
            {cards.map(card => (
              <Grid item key={card.index} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.image}
                    title={card.title}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.heading}
                    </Typography>
                    <Typography>
                    {card.description}
                    </Typography>
                  </CardContent>
                  {/* <CardActions>
                    <Button size="small" color="primary">
                      View
                    </Button>
                    <Button size="small" color="primary">
                      Edit
                    </Button>
                  </CardActions> */}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
    </Layout>
  );
}